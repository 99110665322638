
  
  .ul1 {
    margin: 0;
    padding: 0;
    display: flex;
    top: 50%;
    left: 50%;
  }
  
  .ulli {
    list-style: none;
    margin: 0 15px;
  }
  
  .ullia {
    position: relative; 
    display: block;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 63px;
    background: #333;
    border-radius: 50%;
    font-size: 30px;
    color: #666;
    transition: .5s;
  }
  
  .ullia::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #ffee10;
    transition: .5s;
    transform: scale(.9);
    z-index: -1;
  }
  
  .ullia:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #ffee10;
  }
  
  .ullia:hover {
    color: #ffee10;
    box-shadow: 0 0 5px #ffee10;
    text-shadow: 0 0 5px #ffee10;
  }